<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.32 2.31261C5.19094 1.77355 3.61281 1.50949 1.5 1.50011C1.30074 1.49741 1.10539 1.55551 0.940002 1.66668C0.804248 1.75844 0.693122 1.88215 0.616398 2.02693C0.539674 2.17171 0.499704 2.33313 0.500002 2.49699V11.4376C0.500002 12.042 0.930002 12.4979 1.5 12.4979C3.72094 12.4979 5.94875 12.7054 7.28313 13.9667C7.30138 13.984 7.32433 13.9956 7.34911 14C7.37389 14.0044 7.39942 14.0014 7.42253 13.9914C7.44563 13.9814 7.4653 13.9649 7.47907 13.9438C7.49284 13.9228 7.50012 13.8981 7.5 13.8729V3.33824C7.50006 3.26716 7.48485 3.1969 7.45542 3.13221C7.42598 3.06751 7.383 3.00989 7.32938 2.96324C7.02372 2.70194 6.6842 2.48309 6.32 2.31261V2.31261ZM15.06 1.66574C14.8945 1.55485 14.6992 1.49707 14.5 1.50011C12.3872 1.50949 10.8091 1.7723 9.68 2.31261C9.31582 2.48278 8.97621 2.7012 8.67032 2.96199C8.61681 3.00872 8.57393 3.06637 8.54456 3.13105C8.51518 3.19573 8.49999 3.26595 8.5 3.33699V13.8723C8.49999 13.8965 8.50712 13.9201 8.52049 13.9403C8.53387 13.9604 8.5529 13.9762 8.5752 13.9856C8.59749 13.995 8.62206 13.9975 8.64582 13.993C8.66958 13.9885 8.69147 13.977 8.70875 13.9601C9.51094 13.1632 10.9188 12.497 14.5013 12.4973C14.7665 12.4973 15.0208 12.3919 15.2084 12.2044C15.3959 12.0169 15.5013 11.7625 15.5013 11.4973V2.4973C15.5016 2.33312 15.4616 2.17138 15.3846 2.02635C15.3076 1.88132 15.1962 1.75747 15.06 1.66574V1.66574Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'yellow'
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    }
  }
}
</script>
